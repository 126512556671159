<template>
	<div class="kits-navbar">
		<a href="http://app.keepitsmart.se"><i class="fas fa-user"></i></a>
	</div>
</template>

<style lang="scss">
	.kits-navbar {
		font-size: 1rem;
		font-weight: 700;
		position: fixed;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 1rem;
		top: 0;
		left: 0;
		right: 0;
		height: 60px;
		transition: background .3s ease-in-out;
		background: linear-gradient(rgba(0,0,0,0.5), transparent);
		a {
			text-decoration: none;
			width: 2.5rem;
			height: 2.5rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			color: $kits-gray;
			background-color: white;
			border-radius: 100px;
		}
	}
</style>