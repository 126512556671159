<template>
  <div id="kits-hero" ref="hero" class="kits-hero">
    <img class="logo" src="@/assets/logo-simplewhite.svg" />
    <p class="logo-text">Keep it <span ref="keyword">smart</span></p>
    <div class="backdrop"></div>
    <img class="background" src="@/assets/green-highway.jpg" />
  </div>
  <div class="kits-hero__scroll">
    <i class="fas fa-angle-double-down"></i>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import p5 from "p5";
export default {
  setup() {
    const particles = [];
    const hero = ref(null);
    const keyword = ref(null);

    const createParticle = (s) => {
      const pos = s.createVector(s.random(s.width), s.random(s.height));
      const size = s.random(7, 12);
      const speed = 0.5;
			const lineDistance = 140;
			const vel = s.createVector(s.random(-speed, speed), s.random(-speed, speed));
			const isSpecial = s.random(0,100) > 95;
			let color = isSpecial ? `0,255,0` : `255,255,255`
			const words = [
				'par – Handskar Softtouch XL',
				'st Kapskiva 160mm',
				'st - M8 Bult (fingängad)',
				'st Svetsmask',
				'st - Adflo partikelfilter',
				'st - OK Autrod 12.64 0.8mm 15kg',
				'par - Svetshandske 118 stl 8',
				'st - Munstycksadapter M6 PSF250/302',
				'st - Borr 6,7mm Dormer A1',
				'st - Hålsåg Bimetall 40mm',
				'par -Skyddsglasögon UV-20',
				'st - Tumstock 2 m cm/mm g',
				'st - Bits PH 2 x 50 mm We....',
			];
			const prependAmount = str => Math.floor(s.random(1,25)) + ' ' + str;
			const word = prependAmount(words[Math.floor(s.random(0, words.length -1))]);


      const draw = () => {
				s.noStroke();
				s.fill(`rgba(${color},1)`);
				s.circle(pos.x, pos.y, size);
				if (isSpecial) {
					s.text(word, pos.x + 10, pos.y + 10)
					s.noStroke()
					s.fill(0, 102, 153);
				}
			};

      const update = () => {
        pos.add(vel);
        edges();
      };

      const edges = () => {
        if (pos.x < 0 || pos.x > s.width) {
          vel.x *= -1;
        }
        if (pos.y < 0 || pos.y > s.height) {
          vel.y *= -1;
        }
      };

      const checkParticles = (particles) => {
        particles.forEach((p) => {
          const d = s.dist(pos.x, pos.y, p.pos.x, p.pos.y);
          if (d < lineDistance) {
            s.stroke(`rgba(255,255,255,${(lineDistance - d) * 0.01})`);
            s.line(pos.x, pos.y, p.pos.x, p.pos.y);
          }
        });
			};

      return {
        draw,
        update,
				checkParticles,
        pos
      };
    };

    onMounted(() => {
      const sketch = (s) => {
        s.setup = () => {
          const particlesLength = Math.floor(hero.value.clientWidth / 10);
          const canvas = s.createCanvas(hero.value.clientWidth, hero.value.clientHeight);
          canvas.parent("kits-hero");

          for (let i = 0; i < particlesLength; i++) {
            particles.push(createParticle(s));
          }
        };

        s.draw = () => {
          s.clear();
          particles.forEach((p, index) => {
            p.update();
						p.draw();
            p.checkParticles(particles.slice(index));
          });
        };

        s.windowResized = () => {
          s.resizeCanvas(hero.value.clientWidth, hero.value.clientHeight);
        };
      };

      const sketchInstance = new p5(sketch);
    });

    return {
      hero,
      keyword
    };
  }
};
</script>

<style lang="scss">
body {
  overflow: hidden;
  animation: body 0.1s forwards;
  animation-delay: 6s;
  @include scrollbar(0px);
}
.kits-hero {
  width: 100%;
  position: relative;
  height: calc(100vh);
  background: black;
  animation: hero 1s ease-in-out forwards;
  animation-delay: 5s;
  overflow: hidden;

  &__scroll {
    height: 5vh;
    background-color: $kits-gray;
    display: flex;
    justify-content: center;
		align-items: center;
		border-bottom: 4px solid $kits-green;
    i {
      animation: bounce 3s ease-in-out infinite;
      color: white;
      font-size: 1.5rem;
    }
  }

  canvas {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 0.5s;
    z-index: 2;
    position: relative;
  }
  .logo {
    opacity: 0;
    animation: logoAnimation 2s ease-in-out forwards;
    animation-delay: 2s;
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
  }
  .logo-text {
    left: 50%;
    top: 50%;
    z-index: 4;
    opacity: 0;
    transform: translate(-10%, -50%);
    position: absolute;
    animation: textAnimation 2s ease-in-out forwards;
    animation-delay: 3.5s;
    font-family: "Anton";
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    word-spacing: 100vw;
    line-height: 1;
    display: block;
    width: 150px;
    margin: 0;
  }
  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 3;
  }
  .background {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    filter: blur(5px);
    object-fit: cover;
  }
}
#particles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hero {
  from {
    height: 100vh;
  }
  to {
    height: 95vh;
  }
}

@keyframes logoAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-100%, -50%);
  }
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translate(0%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(20%, -50%);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes body {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}
</style>
