<template>
  <div class="kits-main">
    <Navbar />
    <Hero />
		<Usp :data="data"/>
  </div>
</template>

<script>
import Navbar from "@/components/navbar/Navbar";
import Hero from "@/components/Hero";
import Usp from "@/components/usp/Usp.vue";
export default {
  components: {
    Navbar,
		Hero,
		Usp
	},
	setup() {
		const data = [
			{
				"title": "Hållbarhet",
				"icon": 'leaf',
				"color": 'green',
				"body": "Vår plan är att bidra med lösningar som tar hänsyn till miljön och optimerar hur företags försörjning av förbrukningsmaterial tillhandahålls."
			},
			{
				"title": "Konkurrenskraft",
				"icon": 'business-time',
				"color": 'blue',
				"body": "Genom att erbjuda ett effektivt och modernt sätt att hantera material gör vi företag mer konkurrenskraftiga. Förenklad distribution och optimerade lager säkrar dina kunders tillgång till material."
			},
			{
				"title": "Kostnadseffektivitet",
				"icon": 'money-bill-wave',
				"color": 'orange',
				"body": "Vi hjälper dig att minimera din kapitalbindning. Du kan frigöra mer resurser och satsa större på utveckling och förnyelse."
			},
			{
				"title": "Kvalitet",
				"icon": 'star',
				"color": 'purple',
				"body": "Tillverkningsindustrin svarar idag för 20% av Sveriges BNP. En fungerande försörjningskedja av det material som krävs är en en förutsättning för konkurrenskraft och utveckling."
			}
		]

		return {
			data
		}
	}
};
</script>
