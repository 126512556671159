<template>
  <Section>
    <div class="kits-usp">
      <div class="kits-usp__item" v-for="item in data" :key="`usp-item-${item.title}`">
        <div class="kits-usp__item-title">
          <UspIcon :title="item.title" :icon="item.icon" :color="item.color" />
        </div>
        <div class="kits-usp__item-content" :class="item.color">
          <p>{{ item.body }}</p>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import UspIcon from "./UspIcon";
import Section from "@/components/section/Section";
export default {
  props: ["data"],
  components: {
    UspIcon,
    Section
  },
  setup() {}
};
</script>

<style lang="scss">
.kits-usp {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  max-width: 1500px;
  margin: 0 auto;
	justify-content: center;
	padding: 5rem 0;
  &__item {
    width: 300px;
    margin: 1rem 2rem 3rem;
    &-title {
      text-align: center;
    }
    &-content {
      font-family: "Raleway", sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      padding: 1.3rem;
			border-bottom: 4px solid black;
			color: white;
			height: 200px;
      &.green {
				border-color: $kits-green;
      }
      &.blue {
				border-color: $kits-blue;
      }
      &.orange {
				border-color: $kits-orange;
      }
      &.purple {
				border-color: $kits-dark-purple;
      }
    }
  }
}
</style>
