<template>
  <div class="kits-usp-head" :class="color">
    <div class="kits-usp-icon"><i :class="`fas fa-${icon}`"></i></div>
    <div class="kits-usp-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "color"],
  setup() {}
};
</script>

<style lang="scss">
.kits-usp-head {
  .kits-usp-icon {
    position: relative;
    font-size: 3rem;
    width: 12rem;
    height: 12rem;
    border: 4px solid gray;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1.75rem;
    &:after {
      display: block;
      position: absolute;
      background-color: gray;
      content: "";
      width: 4px;
      height: 2rem;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
  .kits-usp-title {
    padding: 1rem 0.5rem;
    background-color: gray;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    color: white;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    font-family: "Raleway", sans-serif;
	}
	&.green {
		.kits-usp-icon {
			color: $kits-green;
			border-color: $kits-green;
			&:after {
				background-color: $kits-green;
			}
		}
		.kits-usp-title {
			background-color: $kits-green;
		}
	}
	&.blue {
		.kits-usp-icon {
			color: $kits-blue;
			border-color: $kits-blue;
			&:after {
				background-color: $kits-blue;
			}
		}
		.kits-usp-title {
			background-color: $kits-blue;
		}
	}
	&.orange {
		.kits-usp-icon {
			color: $kits-orange;
			border-color: $kits-orange;
			&:after {
				background-color: $kits-orange;
			}
		}
		.kits-usp-title {
			background-color: $kits-orange;
		}
	}
	&.purple {
		.kits-usp-icon {
			color: $kits-dark-purple;
			border-color: $kits-dark-purple;
			&:after {
				background-color: $kits-dark-purple;
			}
		}
		.kits-usp-title {
			background-color: $kits-dark-purple;
		}
	}
}
</style>
