<template>
	<section class="kits-section">
		<slot />
	</section>
</template>

<style lang="scss">
	.kits-section {
		background-color: $kits-gray;
	}
</style>